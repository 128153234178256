import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  useLocation,
} from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { AppSync } from "./aws-exports.js";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useQuery,
  createHttpLink,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Verify } from "./Pages/Verify";
import { Setup } from "./Pages/Setup";
import Animations from "./Pages/Animations";
import AnimationLibrary from "./Components/AnimationsLibrary/AnimationsLibrary";
import Settings from "./Pages/Settings";
import SidePanel from "./Components/SidePanel";
import { GET_USER, GET_APP } from "./Queries";
import { UPDATE_USER } from "./Mutations";
import DemoSlider from "./Components/DemoCarousel";
import { Toaster } from "react-hot-toast";
import triggerStripeCheckout from "./Helpers/stripeTrigger";
import { WebflowSetup } from "./Pages/WebflowSetup";
import PricingModal from "./Components/PricingModal";
import ExtensionModal from "./Components/ExtensionModal";
import ExtensionIntro from "./Components/ExtensionIntro";

const httpLink = createHttpLink({
  uri: AppSync.aws_appsync_graphqlEndpoint,
});

function sendAuthDataToExtension(auth) {
  window.postMessage({ type: "ROMBO_APP", auth: JSON.stringify(auth) }, "*");
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function protectedRoute({ component, auth, appId, app_url }) {
  return auth && appId ? (
    component
  ) : auth && !appId ? (
    <Navigate to={`/setup`} />
  ) : (
    <Navigate to="/signup" />
  );
}

function WrappedRouter({ auth }) {
  const [updateUser] = useMutation(UPDATE_USER);
  const [pricingModal, setPricingModal] = useState(false);
  const [extensionOpen, setExtensionOpen] = useState(checkIfExtensionOpen());

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useQuery(GET_USER, {
    variables: { user_id: auth?.attributes?.username },
  });

  let appId =
    auth && auth.attributes
      ? auth.attributes["custom:app_id"]
      : auth && auth.signInUserSession
      ? auth.signInUserSession.idToken.payload["custom:app_id"]
      : userData && userData.userData.app_id
      ? userData.userData.app_id
      : null;

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id: appId },
  });
  // Get path from  react-router

  const { app_name, app_url, admin_name } = appData?.getApp || {};

  function checkIfExtensionOpen() {
    // Look for #rombo-root in DOM
    const romboRoot = document.getElementById("rombo-root");
    return romboRoot ? true : false;
  }

  async function updateUserName() {
    await updateUser({
      variables: {
        user_id: auth.username,
        full_name: auth.attributes.name,
        email: auth.attributes.email,
        name: app_name || null,
        app_id: appId,
      },
    });
  }

  React.useEffect(() => {
    if (
      auth &&
      auth.attributes &&
      auth.attributes.name &&
      userData &&
      !userData?.getUser?.full_name
    ) {
      try {
        updateUserName();
      } catch (e) {
        console.log(e);
      }
    }
  }, [userData]);

  if (userError || appError) {
    console.log("Error", userError || appError);
  }

  if (!userLoading && !appLoading)
    return (
      <Router>
        {pricingModal && (
          <PricingModal
            setPricingModal={setPricingModal}
            appId={appId}
            authEmail={auth.attributes.email}
          />
        )}
        {extensionOpen && (
          <ExtensionModal appId={appId} setExtensionOpen={setExtensionOpen} />
        )}

        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              background: "#010101",
              color: "#fff",
            },
            iconTheme: {
              primary: "#FDF566",
              secondary: "#010101",
            },

            // Default options for specific types
            success: {
              duration: 3000,
              theme: {
                primary: "#FDF566",
                secondary: "#010101",
              },
            },
          }}
        />
        <SidePanel
          {...{ auth }}
          {...{ setPricingModal }}
          userData={userData}
          appId={appId}
          extensionOpen={checkIfExtensionOpen()}
          id="intercom-container"
        />
        {extensionOpen && !auth && (
          <ExtensionIntro setExtensionOpen={setExtensionOpen} />
        )}
        <main class={"ml-60"} id="intercom-container">
          <Routes>
            <Route path="animations">
              <Route
                path=":app_id"
                index
                element={
                  auth && appId ? (
                    protectedRoute({
                      component: <Animations {...{ auth }} />,
                      auth,
                      appId,
                      app_url,
                    })
                  ) : (
                    <Navigate to="/setup" />
                  )
                }
              />
            </Route>
            <Route path="library">
              <Route
                path=":app_id"
                index
                element={protectedRoute({
                  component: <AnimationLibrary />,
                  auth,
                  appId,
                })}
              />
            </Route>
            <Route path="settings">
              <Route
                path=":app_id"
                index
                element={
                  auth && appId ? (
                    protectedRoute({
                      component: (
                        <Settings
                          user_id={
                            auth.attributes
                              ? auth.attributes.sub
                              : `${auth.username}`
                          }
                        />
                      ),
                      auth,
                      appId,
                    })
                  ) : (
                    <Navigate to="/setup" />
                  )
                }
              />
            </Route>
            <Route
              path="setup"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth &&
                  (auth.attributes || auth.signInUserSession) &&
                  !appId ? (
                  <Setup
                    {...{ app_name, app_url, admin_name, appId, auth }}
                    user_id={
                      auth.attributes ? auth.attributes.sub : auth.username
                    }
                    email={
                      auth.attributes
                        ? auth.attributes.email
                        : auth.signInUserSession.idToken.payload.email
                    }
                  />
                ) : (
                  <Navigate to="/signup" />
                )
              }
            />
            <Route path="webflow-setup" element={<WebflowSetup />} />
            <Route path="extension">
              <Route path="connect" element={<div>Connect</div>} />
              <Route path="connected" element={<div>Connected</div>} />
            </Route>
            <Route
              path="signup"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <aside
                    id="intercom-container"
                    className="demo-slider flex items-center justify-center absolute left-2/4 w-2/4 h-full overflow-hidden"
                  >
                    <DemoSlider />
                  </aside>
                )
              }
            />
            <Route path="login" element={auth ? <Navigate to="/" /> : null} />

            <Route
              path="verify/:email/:code"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <Verify {...{ auth }} />
                )
              }
            />
            <Route
              path="*"
              element={
                auth && appId ? (
                  <Navigate to={`/animations/${appId}`} />
                ) : auth && !appId ? (
                  <Navigate to={`/setup`} />
                ) : (
                  <Navigate to={`/signup`} />
                )
              }
            />
          </Routes>
        </main>
      </Router>
    );
  return "";
}

function App() {
  const [auth, setAuth] = useState(null);
  const [connected, setConnected] = useState(false);

  function eventReceiver(event) {
    try {
      console.log("Received in app", event.data.type);
      if (event.data.type === "ROMBO_SCRIPT") {
        console.log("CONNECTED TO APP!");
        // window.postMessage({ type: "TO_ROMBO_JS", auth: auth }, "*");
        setConnected(true);
      }
    } catch (e) {
      console.log(e, "Event receiver failed");
    }
  }

  useEffect(() => {
    // Post message to parent to update the url
    // if (auth) {
    window.addEventListener("message", eventReceiver, false);
    try {
      console.log("posting message");

      console.log("posted message");
      // window.postMessage({ type: "tester", auth: JSON.stringify(auth) }, "*");
    } catch (e) {
      console.log(e, "Post message failed");
    }
    // }
  }, []);

  useEffect(() => {
    console.log("connected", connected, "auth", auth);
    if (connected && auth) {
      console.log("Should send app event to script");
      sendAuthDataToExtension(auth);
    }
  }, [connected, auth]);

  useEffect(() => {
    let findToken = async () => {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      console.log("Found authed user", cognitoUser);
      setAuth(cognitoUser);
    };
    findToken().catch((e) => {
      setAuth(false);
      new Error(e);
    });
  }, []);

  const getCustomStateFromUrl = () => {
    console.log(window.location);
    const params = new URLSearchParams(window.location.search);
    const state = params.get("state");
    if (state) {
      const customStateHex = state.split("-")[1];
      return Buffer.from(customStateHex, "hex").toString();
    }
    return null;
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      console.log("Hub event", payload);
      // if customOAuthState
      if (payload.event === "customOAuthState") {
        console.log("Custom OAuth state", payload.customOAuthState);
        localStorage.setItem("authCustomState", payload.data);
      }
      if (payload.event === "signIn") {
        console.log("Sign in successful", payload);

        // Check URL for state parameter
        const customState = getCustomStateFromUrl();
        console.log("Checking for custom state", customState);
        if (customState) {
          localStorage.setItem("authCustomState", customState);
        }
      }
    });
  }, []);

  if (auth !== null)
    return (
      <ApolloProvider client={client}>
        <WrappedRouter {...{ auth }} />
      </ApolloProvider>
    );
  return null;
}

export default App;
