import React, { useEffect, useState, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { CREATE_APP } from "../Mutations";
import Window from "../Components/Window";
import "./Setup.css";

const getAppDetails = (email) => {
  const [username, domain] = email.split("@");
  const domainName = domain.split(".")[0];

  if (commonProviders.includes(domainName.toLowerCase())) {
    return { app_name: username, app_url: null };
  }

  return { app_name: domainName, app_url: domain };
};

const PLACEHOLDER_EXAMPLES = ["apple.com", "localhost:5173", "linear.app"];

export const Setup = ({ auth }) => {
  const [createApp] = useMutation(CREATE_APP);
  const [appDetails, setAppDetails] = useState(
    getAppDetails(auth.attributes.email)
  );
  const [errors, setErrors] = useState({});
  const [placeholder, setPlaceholder] = useState("");
  const animationState = useRef({
    currentExample: 0,
    currentChar: 0,
    isDeleting: false,
    timeoutId: null,
  });

  useEffect(() => {
    const type = () => {
      const state = animationState.current;
      const example = PLACEHOLDER_EXAMPLES[state.currentExample];

      if (state.isDeleting) {
        setPlaceholder(example.substring(0, state.currentChar - 1));
        state.currentChar--;
      } else {
        setPlaceholder(example.substring(0, state.currentChar + 1));
        state.currentChar++;
      }

      let typeSpeed = state.isDeleting ? 50 : 100;

      if (!state.isDeleting && state.currentChar === example.length) {
        typeSpeed = 2000;
        state.isDeleting = true;
      } else if (state.isDeleting && state.currentChar === 0) {
        state.isDeleting = false;
        state.currentExample =
          (state.currentExample + 1) % PLACEHOLDER_EXAMPLES.length;
        typeSpeed = 500;
      }

      state.timeoutId = setTimeout(type, typeSpeed);
    };

    type();

    return () => {
      if (animationState.current.timeoutId) {
        clearTimeout(animationState.current.timeoutId);
      }
    };
  }, []);

  const handleInputChange = (field, value) => {
    setAppDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!appDetails.app_name) {
      newErrors.app_name = "Please enter an app name";
    }

    if (appDetails.app_url) {
      const domainRegex =
        /^(localhost(:[0-9]+)?|[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,})$/;
      if (!domainRegex.test(appDetails.app_url)) {
        newErrors.app_url =
          "Please enter a valid domain or localhost (e.g. example.com, localhost:3000)";
      }
    }

    if (auth.attributes.email && !validateEmail(auth.attributes.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const createAppAsync = async () => {
    if (!validateFields()) return;
    let createdApp = await createApp({
      variables: {
        app_name: appDetails.app_name,
        app_url: appDetails.app_url || "",
        user_id: auth.attributes.sub,
        billing_email: auth.attributes.email,
        admin_name: auth.attributes.name || "",
      },
    });
    //   {
    //     "data": {
    //         "createApp": {
    //             "app_id": "r0ped963y",
    //             "app_name": "nicksmithr+123123123",
    //             "app_url": null,
    //             "admin_name": null,
    //             "billing_email": null,
    //             "__typename": "App"
    //         }
    //     }
    // }
    if (createdApp?.data?.createApp?.app_id) {
      window.location.reload();
    } else {
      console.log("Error creating app", createdApp);
    }
  };

  useEffect(() => {
    createAppAsync();
  }, []);

  return (
    <></>
    // <Window randomCode={123} element={null}>
    //   <div className="flex items-center flex-col mt-20">
    //     <div className="blob" />

    //     <div className="w-full max-w-md space-y-4">
    //       <div className="space-y-0.5">
    //         <label className="text-sm font-medium">Email</label>
    //         <input
    //           type="email"
    //           value={auth.attributes.email}
    //           onChange={(e) => handleInputChange("email", e.target.value)}
    //           className={`w-full p-2 border rounded ${
    //             errors.email ? "bg-red-50 border-red-300" : "border-gray-300"
    //           }`}
    //           placeholder="Enter email address"
    //         />
    //         {errors.email && (
    //           <p className="text-sm text-red-600">{errors.email}</p>
    //         )}
    //       </div>

    //       <div className="space-y-0.5">
    //         <label className="text-sm font-medium">
    //           Full Name <span className="text-gray-400">(optional)</span>
    //         </label>
    //         <input
    //           type="text"
    //           value={auth.attributes.name || ""}
    //           onChange={(e) => handleInputChange("name", e.target.value)}
    //           className="w-full p-2 border rounded border-gray-300"
    //           placeholder="Enter your full name"
    //         />
    //       </div>

    //       <div className="flex gap-4">
    //         <div className="space-y-0.5 flex-1">
    //           <label className="text-sm font-medium">App Name</label>
    //           <input
    //             type="text"
    //             value={appDetails.app_name || ""}
    //             onChange={(e) => handleInputChange("app_name", e.target.value)}
    //             className={`w-full p-2 border rounded ${
    //               errors.app_name
    //                 ? "bg-red-50 border-red-300"
    //                 : !appDetails.app_name
    //                 ? "bg-yellow-50 border-yellow-300"
    //                 : "border-gray-300"
    //             }`}
    //             placeholder="Enter app name"
    //           />
    //           {errors.app_name && (
    //             <p className="text-sm text-red-600">{errors.app_name}</p>
    //           )}
    //         </div>

    //         <div className="space-y-0.5 flex-1">
    //           <label className="text-sm font-medium">
    //             App URL <span className="text-gray-400">(optional)</span>
    //           </label>
    //           <input
    //             type="text"
    //             value={appDetails.app_url || ""}
    //             onChange={(e) => handleInputChange("app_url", e.target.value)}
    //             className={`w-full p-2 border rounded ${
    //               errors.app_url
    //                 ? "bg-red-50 border-red-300"
    //                 : "border-gray-300"
    //             }`}
    //             placeholder={placeholder}
    //           />
    //           {errors.app_url && (
    //             <p className="text-sm text-red-600">{errors.app_url}</p>
    //           )}
    //         </div>
    //       </div>

    //       <button
    //         onClick={createAppAsync}
    //         className="w-full px-4 py-2 text-white bg-black border border-black rounded hover:bg-gray-800 transition-colors"
    //       >
    //         Create app
    //       </button>
    //     </div>
    //   </div>
    // </Window>
  );
};

const commonProviders = [
  "gmail",
  "hotmail",
  "yahoo",
  "outlook",
  "aol",
  "msn",
  "live",
  "icloud",
  "gmx",
  "yohomail",
  "yandex",
  "tutanota",
  "protonmail",
];
