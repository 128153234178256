import { useEffect, useState } from "react";
import "./SidePanel.css";
import { useNavigate, useLocation } from "react-router";
import {
  createGoogleScript,
  signInPasswordless,
  signUpPasswordless,
} from "../../Pages/SignUp";
import { Button } from "../Core/Button";
import { Input } from "../Core/Input";
import Auth, { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useQuery, gql } from "@apollo/client";
import UsageTracker from "../UsageTracker/UsageTracker";
import VerfiyEmail from "../VerifyEmail/VerifyEmail";

function SidePanel({
  state,
  auth,
  appId,
  userData,
  setPricingModal,
  extensionOpen,
}) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailed, setEmailed] = useState(null);
  const [showEmail, setShowEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [timeLeft, setTimeLeft] = useState(60);
  let locationUrl = useLocation();

  // useEffect(() => {
  //   // exit early when we reach 0
  //   if (!timeLeft) return;

  //   // save intervalId to clear the interval when the
  //   // component re-renders
  //   const intervalId = setInterval(() => {
  //     setTimeLeft(timeLeft - 1);
  //   }, 1000);

  //   // clear interval on re-render to avoid memory leaks
  //   return () => clearInterval(intervalId);
  //   // add timeLeft as a dependency to re-rerun the effect
  //   // when we update it
  // }, [timeLeft]);

  let location = useLocation();
  let navigate = useNavigate();
  state = location.pathname.split("/")[1];

  switch (state) {
    case "verify":
      return <></>;
    case "signup":
      if (emailed)
        return (
          <section
            id="intercom-container"
            className="bg-[#F6F8FD] fade-in-left justify-center flex flex-col transform w-2/4	 items-center fixed top-0 left-0 bottom-0 md:shadow-none h-full border-r border-[#EBEDF4] overflow-y-auto ~bg-white"
          >
            <VerfiyEmail {...{ emailed }} />
          </section>
        );
      return (
        <section
          id="intercom-container"
          className="bg-[#F6F8FD] fade-in-left justify-center flex flex-col transform w-2/4	 items-center fixed top-0 left-0 bottom-0 md:shadow-none h-full border-r border-[#EBEDF4] overflow-y-auto ~bg-white"
        >
          {locationUrl?.search?.includes("link_expired") ? (
            <div className="bg-red-300 shadow-sm shadow-red-100 border border-solid border-red-400 px-1 text-sm py-0.5 rounded-sm text-red-900 font-semibold mb-1">
              The link you were previously sent has expired. Please try again!
            </div>
          ) : (
            <></>
          )}
          {extensionOpen && (
            <div className="text-sm text-black mx-8 mb-4 p-2 rounded-lg text-center">
              <div className="font-semibold  text-l  outline outline-1 outline-primary-600/10">
                Thanks for installing the Rombo Extension 🙏{" "}
              </div>
              <div>Let's get you setup 👇</div>
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-md mb-4 mx-8 text-sm">
              {errorMessage}
            </div>
          )}
          <svg
            className="w-1/6 opacity-20 fade-in"
            viewBox="0 0 330 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.9 75.2201C23.31 75.8801 14.05 76.5201 4.45 77.1801C4.79 75.6201 5.19 74.2801 5.37 72.9101C5.61 71.1101 5.89 69.2801 5.77 67.4801C4.66 50.5301 3.55 33.5901 2.23 16.6501C1.99 13.5501 0.81 10.5201 0 7.23012C0.42 7.15012 1.05 6.96012 1.69 6.92012C13.72 6.09012 25.75 5.11012 37.79 4.51012C44.9 4.15012 51.38 6.17012 56.36 11.6301C63.68 19.6601 64.1 32.9401 57.35 41.5001C56.74 42.2801 55.93 42.9501 55.51 43.8201C55.17 44.5201 54.91 45.6401 55.24 46.2401C59.29 53.5401 66.18 66.3101 70.38 73.5301C71.24 75.0101 72.43 76.3101 73.68 77.9801C72.77 78.1501 72.14 78.3201 71.51 78.3701C63.21 78.9601 54.91 79.4801 46.61 80.1301C44.74 80.2801 44.41 79.6701 43.95 77.8801C43.1 74.5501 38.83 65.8701 37.4 62.7201C36.2 60.1001 34.81 57.5601 33.31 55.1001C32.86 54.3701 31.74 54.0401 30.93 53.5201C30.59 54.3001 29.89 55.1101 29.96 55.8501C30.47 61.0701 31.11 66.2801 31.8 71.4801C31.95 72.6501 32.47 73.7701 32.91 75.2201H32.9ZM27.57 30.3001C27.57 30.3001 27.57 30.3001 27.56 30.3001C27.64 31.4401 27.71 32.5801 27.8 33.7101C28.05 37.1901 28.04 37.1501 31.6 36.8901C34.58 36.6701 36.59 35.2501 37.55 32.4001C38.56 29.4001 38.08 26.6001 35.89 24.3701C33.65 22.0901 30.73 23.2001 28.07 23.4701C27.74 23.5001 27.27 24.5101 27.27 25.0601C27.27 26.8001 27.47 28.5501 27.59 30.2901L27.57 30.3001Z"
              fill="#231F20"
            ></path>
            <path
              d="M90.64 76.94C78.52 74.48 69.96 69.31 66.22 58.52C61.4 44.61 67.65 26.5 83.87 21.52C95.07 18.08 110.16 21.24 118 31.59C123.97 39.47 124.88 48.39 122.08 57.62C118.68 68.83 110.93 75.31 99.3 76.87C96.02 77.31 92.63 76.94 90.63 76.94H90.64ZM100 49.64C99.95 47.69 100.06 45.42 99.8 43.19C99.53 40.9 97.94 39.57 95.71 39.19C93.59 38.83 91.77 39.49 90.62 41.4C88.33 45.18 87.77 49.39 88.15 53.66C88.4 56.47 90.02 58.01 92.31 58.45C94.7 58.91 96.67 57.99 98.03 55.34C98.88 53.68 99.27 51.79 99.99 49.64H100Z"
              fill="#231F20"
            ></path>
            <path
              d="M122.18 28.65C122.24 28.62 122.58 28.35 122.97 28.27C127.55 27.38 132.14 26.55 136.72 25.66C138.57 25.3 140.14 25.82 141.5 27C142.2 27.61 142.84 28.3 143.43 29.01C145.57 31.58 145.49 31.48 148.37 29.67C155.21 25.35 162.35 23.65 169.82 28.1C171.2 28.92 172.38 30.18 173.38 31.45C174.34 32.67 175.04 32.7 176.18 31.76C179.42 29.1 182.95 26.96 187.13 26.04C197.01 23.87 206.48 29.14 207.06 40.7C207.45 48.5 207.29 56.33 207.32 64.15C207.34 68.74 207.33 73.32 209.65 77.84H184.39C184.76 75.52 185.39 73.3 185.43 71.06C185.59 62.38 185.58 53.7 185.58 45.02C185.58 42.1 184.41 41.21 181.49 41.68C180.92 41.77 180.35 41.85 179.8 42.01C177.76 42.6 176.62 43.6 176.75 46.1C177 50.96 176.77 55.85 176.84 60.73C176.92 66.41 176.09 72.2 178.96 77.85H153.86C156.33 67.18 154.93 56.48 155.25 45.84C155.37 42.01 154.12 41.08 150.41 41.79C146.7 42.5 146.3 42.95 146.34 46.75C146.42 55.07 146.49 63.39 146.68 71.7C146.73 73.67 147.26 75.62 147.61 77.82H122.96C125.96 64.24 126.74 58.76 126.74 42.29C126.74 35.83 122.98 31.64 122.17 28.63L122.18 28.65Z"
              fill="#231F20"
            ></path>
            <path
              d="M213.37 78.4C213.37 78.4 213.35 68.26 213.14 62.94C212.65 50.3 212.2 37.66 211.73 25.02C211.6 21.44 211.7 15.09 211.28 11.54C210.97 8.89996 209.26 6.29996 208.15 3.56996C207.65 2.33996 208.49 0.979956 209.82 0.899956C210.48 0.859956 211.1 0.829956 211.42 0.809956C218.52 0.529956 225.61 0.269956 232.71 0.0199561C235.37 -0.0700439 235.21 0.0199561 235.07 2.69996C234.73 9.19996 234.59 18.47 234.37 25.53C236.91 24.49 238.66 23.69 240.48 23.06C247.3 20.67 253.31 22.6 258.58 26.97C266 33.14 268.89 41.42 268.98 50.84C269.07 59.36 266.52 66.83 260.21 72.83C253.39 79.32 243.18 80.67 235.05 75.97C233.75 75.22 232.84 75.15 231.93 76.38C230.64 78.13 228.87 78.58 226.74 78.6C221.72 78.65 218.91 78.19 213.35 78.4H213.37ZM235.23 51.09C235.31 53.22 235.39 55.35 235.47 57.48C235.61 61.31 236.71 62.04 240.47 60.94C242.44 60.37 243.84 59.21 244.57 57.31C245.96 53.7 245.88 50.05 244.95 46.34C243.94 42.27 241.02 40.23 236.88 40.67C235.42 40.83 234.87 41.5 234.93 42.88C235.05 45.62 235.14 48.35 235.24 51.09H235.23Z"
              fill="#231F20"
            ></path>
            <path
              d="M309.15 77.03C297.11 79.84 287.17 78.71 279.28 70.45C269.11 59.81 267.25 40.7401 279.92 29.4601C288.67 21.6601 303.7 18.2601 315.15 24.4101C323.86 29.0901 328.4 36.8201 329.7 46.3701C331.28 57.9801 326.93 67.1 317.01 73.35C314.21 75.12 310.98 76.19 309.16 77.03H309.15ZM306.3 48.3201C305.44 46.5601 304.6 44.4501 303.43 42.5401C302.23 40.5701 300.23 40.02 298.05 40.6C295.97 41.15 294.6 42.5101 294.34 44.7301C293.84 49.1201 295.07 53.1801 297.2 56.9001C298.6 59.3501 300.71 60.0701 302.98 59.5201C305.34 58.9501 306.76 57.2901 306.89 54.3101C306.97 52.4501 306.54 50.5701 306.3 48.3101V48.3201Z"
              fill="#231F20"
            ></path>
          </svg>
          <div class="google-button">
            <button
              onClick={() => {
                Auth.federatedSignIn({
                  provider: CognitoHostedUIIdentityProvider.Google,
                  customState: "chrome",
                });
              }}
            >
              <div
                tabindex="0"
                role="button"
                aria-labelledby="button-label"
                class="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK"
              >
                <div class="nsm7Bb-HzV7m-LgbsSe-MJoBVe"></div>
                <div class="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb ">
                  <div class="nsm7Bb-HzV7m-LgbsSe-Bz112c">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      class="LgbsSe-Bz112c"
                    >
                      <g>
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </g>
                    </svg>
                  </div>
                  <span class="nsm7Bb-HzV7m-LgbsSe-BPrWId">
                    Sign in with Google
                  </span>
                  <span class="L6cTce" id="button-label">
                    Sign in with Google
                  </span>
                </div>
              </div>
            </button>
          </div>
          {/* Divider "or" */}
          <div>
            <div className="flex justify-center text-xs">
              <span className="bg-background uppercase flex py-2 px-2 items-center justify-center text-muted-foreground text-dark">
                or
              </span>
            </div>
          </div>
          <div class="flex border-solid bg-white border border-gray-100 rounded-sm">
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="example@email.com"
            />
            <Button
              shadow
              bright
              icon="email"
              onClick={async () => {
                try {
                  setErrorMessage(null);
                  console.log("Try sign up passwordless");
                  let res = await signUpPasswordless(email, name, "web");
                  if (res?.error === "UsernameExistsException") {
                    setErrorMessage(
                      "Account already exists with Google — try logging in with Google"
                    );
                    return;
                  }
                  console.log("Try sign in passwordless");
                  await signInPasswordless(email);
                  setEmailed(email);
                } catch (e) {
                  console.log(e);
                  setErrorMessage(e.message || "An error occurred");
                }
              }}
            >
              Send
            </Button>
          </div>
          <p className="fade-in text-gray-800 rounded-lg px-2 py-2 w-2/3 mt-10 text-center text-xs text-muted-foreground">
            By signing up you agree to Rombo's{" "}
            <a
              href="https://rombo.co/terms"
              className="opacity-50 underline-offset-4 hover:text-primary"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://rombo.co/privacy"
              className="opacity-50 underline-offset-4 hover:text-primary"
            >
              Privacy Policy
            </a>
          </p>
        </section>
      );
    default:
      return (
        <section
          class={`bg-[#F6F8FD] flex flex-col transform w-60 fixed top-0 left-0 bottom-0 md:shadow-none h-full border-r border-[#EBEDF4] overflow-y-auto ~bg-white ${
            state === "setup" ? "justify-between" : ""
          }`}
        >
          <div class="flex-none overflow-visible rombo-logo w-24 pt-6 pl-2 mx-3 pb-0">
            <svg
              viewBox="0 -20 350 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32.9 75.2201C23.31 75.8801 14.05 76.5201 4.45 77.1801C4.79 75.6201 5.19 74.2801 5.37 72.9101C5.61 71.1101 5.89 69.2801 5.77 67.4801C4.66 50.5301 3.55 33.5901 2.23 16.6501C1.99 13.5501 0.81 10.5201 0 7.23012C0.42 7.15012 1.05 6.96012 1.69 6.92012C13.72 6.09012 25.75 5.11012 37.79 4.51012C44.9 4.15012 51.38 6.17012 56.36 11.6301C63.68 19.6601 64.1 32.9401 57.35 41.5001C56.74 42.2801 55.93 42.9501 55.51 43.8201C55.17 44.5201 54.91 45.6401 55.24 46.2401C59.29 53.5401 66.18 66.3101 70.38 73.5301C71.24 75.0101 72.43 76.3101 73.68 77.9801C72.77 78.1501 72.14 78.3201 71.51 78.3701C63.21 78.9601 54.91 79.4801 46.61 80.1301C44.74 80.2801 44.41 79.6701 43.95 77.8801C43.1 74.5501 38.83 65.8701 37.4 62.7201C36.2 60.1001 34.81 57.5601 33.31 55.1001C32.86 54.3701 31.74 54.0401 30.93 53.5201C30.59 54.3001 29.89 55.1101 29.96 55.8501C30.47 61.0701 31.11 66.2801 31.8 71.4801C31.95 72.6501 32.47 73.7701 32.91 75.2201H32.9ZM27.57 30.3001C27.57 30.3001 27.57 30.3001 27.56 30.3001C27.64 31.4401 27.71 32.5801 27.8 33.7101C28.05 37.1901 28.04 37.1501 31.6 36.8901C34.58 36.6701 36.59 35.2501 37.55 32.4001C38.56 29.4001 38.08 26.6001 35.89 24.3701C33.65 22.0901 30.73 23.2001 28.07 23.4701C27.74 23.5001 27.27 24.5101 27.27 25.0601C27.27 26.8001 27.47 28.5501 27.59 30.2901L27.57 30.3001Z"
                fill="#231F20"
              ></path>
              <path
                d="M90.64 76.94C78.52 74.48 69.96 69.31 66.22 58.52C61.4 44.61 67.65 26.5 83.87 21.52C95.07 18.08 110.16 21.24 118 31.59C123.97 39.47 124.88 48.39 122.08 57.62C118.68 68.83 110.93 75.31 99.3 76.87C96.02 77.31 92.63 76.94 90.63 76.94H90.64ZM100 49.64C99.95 47.69 100.06 45.42 99.8 43.19C99.53 40.9 97.94 39.57 95.71 39.19C93.59 38.83 91.77 39.49 90.62 41.4C88.33 45.18 87.77 49.39 88.15 53.66C88.4 56.47 90.02 58.01 92.31 58.45C94.7 58.91 96.67 57.99 98.03 55.34C98.88 53.68 99.27 51.79 99.99 49.64H100Z"
                fill="#231F20"
              ></path>
              <path
                d="M122.18 28.65C122.24 28.62 122.58 28.35 122.97 28.27C127.55 27.38 132.14 26.55 136.72 25.66C138.57 25.3 140.14 25.82 141.5 27C142.2 27.61 142.84 28.3 143.43 29.01C145.57 31.58 145.49 31.48 148.37 29.67C155.21 25.35 162.35 23.65 169.82 28.1C171.2 28.92 172.38 30.18 173.38 31.45C174.34 32.67 175.04 32.7 176.18 31.76C179.42 29.1 182.95 26.96 187.13 26.04C197.01 23.87 206.48 29.14 207.06 40.7C207.45 48.5 207.29 56.33 207.32 64.15C207.34 68.74 207.33 73.32 209.65 77.84H184.39C184.76 75.52 185.39 73.3 185.43 71.06C185.59 62.38 185.58 53.7 185.58 45.02C185.58 42.1 184.41 41.21 181.49 41.68C180.92 41.77 180.35 41.85 179.8 42.01C177.76 42.6 176.62 43.6 176.75 46.1C177 50.96 176.77 55.85 176.84 60.73C176.92 66.41 176.09 72.2 178.96 77.85H153.86C156.33 67.18 154.93 56.48 155.25 45.84C155.37 42.01 154.12 41.08 150.41 41.79C146.7 42.5 146.3 42.95 146.34 46.75C146.42 55.07 146.49 63.39 146.68 71.7C146.73 73.67 147.26 75.62 147.61 77.82H122.96C125.96 64.24 126.74 58.76 126.74 42.29C126.74 35.83 122.98 31.64 122.17 28.63L122.18 28.65Z"
                fill="#231F20"
              ></path>
              <path
                d="M213.37 78.4C213.37 78.4 213.35 68.26 213.14 62.94C212.65 50.3 212.2 37.66 211.73 25.02C211.6 21.44 211.7 15.09 211.28 11.54C210.97 8.89996 209.26 6.29996 208.15 3.56996C207.65 2.33996 208.49 0.979956 209.82 0.899956C210.48 0.859956 211.1 0.829956 211.42 0.809956C218.52 0.529956 225.61 0.269956 232.71 0.0199561C235.37 -0.0700439 235.21 0.0199561 235.07 2.69996C234.73 9.19996 234.59 18.47 234.37 25.53C236.91 24.49 238.66 23.69 240.48 23.06C247.3 20.67 253.31 22.6 258.58 26.97C266 33.14 268.89 41.42 268.98 50.84C269.07 59.36 266.52 66.83 260.21 72.83C253.39 79.32 243.18 80.67 235.05 75.97C233.75 75.22 232.84 75.15 231.93 76.38C230.64 78.13 228.87 78.58 226.74 78.6C221.72 78.65 218.91 78.19 213.35 78.4H213.37ZM235.23 51.09C235.31 53.22 235.39 55.35 235.47 57.48C235.61 61.31 236.71 62.04 240.47 60.94C242.44 60.37 243.84 59.21 244.57 57.31C245.96 53.7 245.88 50.05 244.95 46.34C243.94 42.27 241.02 40.23 236.88 40.67C235.42 40.83 234.87 41.5 234.93 42.88C235.05 45.62 235.14 48.35 235.24 51.09H235.23Z"
                fill="#231F20"
              ></path>
              <path
                d="M309.15 77.03C297.11 79.84 287.17 78.71 279.28 70.45C269.11 59.81 267.25 40.7401 279.92 29.4601C288.67 21.6601 303.7 18.2601 315.15 24.4101C323.86 29.0901 328.4 36.8201 329.7 46.3701C331.28 57.9801 326.93 67.1 317.01 73.35C314.21 75.12 310.98 76.19 309.16 77.03H309.15ZM306.3 48.3201C305.44 46.5601 304.6 44.4501 303.43 42.5401C302.23 40.5701 300.23 40.02 298.05 40.6C295.97 41.15 294.6 42.5101 294.34 44.7301C293.84 49.1201 295.07 53.1801 297.2 56.9001C298.6 59.3501 300.71 60.0701 302.98 59.5201C305.34 58.9501 306.76 57.2901 306.89 54.3101C306.97 52.4501 306.54 50.5701 306.3 48.3101V48.3201Z"
                fill="#231F20"
              ></path>
            </svg>
          </div>
          {appId ? (
            <div class="flex-1 px-3 py-2 space-y-px  pt-6 pb-6">
              <Button
                icon="animations"
                showBackground={state === "animations"}
                onClick={() => navigate(`/animations/${appId}`)}
              >
                Animations
              </Button>
              <Button
                icon="library"
                showBackground={state === "library"}
                onClick={() => navigate(`/library/${appId}`)}
              >
                Library
              </Button>
              <Button
                icon="settings"
                showBackground={state === "settings"}
                onClick={() => navigate(`/settings/${appId}`)}
              >
                Settings
              </Button>
            </div>
          ) : (
            <p className="px-4 text-slate-600 text-center">
              Welcome to Rombo!
              <br />
              Create your Rombo workspace, where you can add and manage
              animations <br /> <span class="text-xl jiggle-right">👉</span>
            </p>
          )}
          <UsageTracker {...{ appId, auth, setPricingModal }} />
          <div class="flex-none flex flex-col justify-center items-center align-middle">
            {auth?.attributes?.name ? (
              <h1 class="mb-2 text-sm text-slate-600">
                Signed in as <b>{auth.attributes.name}</b>
              </h1>
            ) : (
              <></>
            )}
            <button
              class="border mb-5 px-2 py-1 text-xs rounded-md border-slate-300 bg-slate-200"
              onClick={() => {
                Auth.signOut();
                window.location.reload();
              }}
            >
              Sign Out ⏎
            </button>
          </div>
        </section>
      );
  }
}

export default SidePanel;
