const AppSync = {
  aws_appsync_graphqlEndpoint:
    "https://rjnjjtxyhjc3fjn7cawaovwgfy.appsync-api.eu-west-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-west-1",
};

const config = {
  identityPoolId: "eu-west-1:ebf54804-e119-457e-8c2e-02a4dd34bec2",
  region: "eu-west-1",
  userPoolId: "eu-west-1_j8BDLhLkp",
  userPoolWebClientId: "6s02igv3082crp8r9uv9pss4to",
  mandatorySignIn: true,
  oauth: {
    domain: "auth.rombo.live",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    // redirectSignIn: "http://localhost:3000/",
    redirectSignIn: "https://app.rom.bo/",
    redirectSignOut: "https://app.rom.bo/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export { AppSync, config };
