import React from "react";
// Import usequery, query for app, check for billing_plan
import { useQuery } from "@apollo/client";
import { GET_APP } from "../Queries";
import triggerStripeCheckout from "../Helpers/stripeTrigger";
import GetStartedStep from "./ExtensionIntro/GetStartedStep";

const styles = `
@keyframes growWidth {
  from { width: 0%; }
  to { width: 100%; }
}
`;

function Features() {
  const [activeTab, setActiveTab] = React.useState(0);

  const tabs = [
    {
      title: "Timeline Builder",
      image: "https://assets.rombo.co/TimelineBuilder.gif",
      duration: 5000, // We'll use default durations since we can't easily get them from URLs
    },
    {
      title: "Save Animations",
      image: "https://assets.rombo.co/SavetoLibrary.gif",
      duration: 3000,
    },
    {
      title: "Designer-Engineering Library",
      image: "https://assets.rombo.co/Library.gif",
      duration: 6000,
    },
  ];

  React.useEffect(() => {
    const timer = setInterval(() => {
      setActiveTab((prev) => (prev + 1) % tabs.length);
    }, tabs[activeTab].duration);

    return () => clearInterval(timer);
  }, [activeTab]);

  return (
    <div className="flex flex-col w-full">
      <style>{`
        @keyframes growWidth {
          from { width: 0%; }
          to { width: 100%; }
        }
      `}</style>
      <div className="flex justify-center">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={`px-3 py-2 border border-black/10 text-sm font-semibold text-left transition-all relative ${
              activeTab === index
                ? "bg-[#212128] text-white"
                : "bg-[#212128]/5 text-black/50 hover:bg-black/10"
            } ${index === 0 ? "rounded-tl-md" : ""} ${
              index === tabs.length - 1 ? "rounded-tr-md" : ""
            }`}
          >
            {tab.title}
            {activeTab === index && (
              <div
                className="absolute bottom-0 left-0 h-[3px] bg-white"
                style={{
                  animation: `growWidth ${tabs[activeTab].duration}ms linear`,
                  width: "0%",
                }}
              />
            )}
          </button>
        ))}
      </div>

      <div className="flex justify-center items-center bg-[#212128] rounded-b-lg">
        <img
          src={tabs[activeTab].image}
          className="h-[200px] object-contain"
          alt={tabs[activeTab].title}
        />
      </div>
    </div>
  );
}

const ExtensionModal = ({ appId, setExtensionOpen }) => {
  // Check if the page contains the id rombo-account-icon
  const romboAccountIcon = document.getElementById("rombo-account-icon");

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useQuery(GET_APP, {
    variables: { app_id: appId },
  });

  if (!appData) return null;

  const { getApp } = appData;

  const isAccountConnected = appData?.getApp?.billing_plan;

  return (
    <div
      id="intercom-container"
      className="w-full fixed inset-0 z-50 flex items-center justify-center"
    >
      <div className="absolute  inset-0 bg-black opacity-80"></div>

      <div className="relative bg-white w-2/3 rounded-md border border-black/10">
        <div className="text-center items-center flex flex-col items-start">
          <h2 className="text-2xl flex items-center font-bold px-8 pt-4">
            <svg
              width="20"
              height="20"
              className="mr-2 inline-block motion-rotate-loop-180 motion-duration-2000 motion-ease-spring-bounciest"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_68_180)">
                <path
                  d="M6.00001 1.35444e-07C4.10501 1.35444e-07 2.41551 0.8785 1.31601 2.2505L3.29251 5.6745C3.37199 5.0123 3.6914 4.40219 4.19032 3.95957C4.68923 3.51695 5.33305 3.27252 6.00001 3.2725H11.3455C10.8427 2.28732 10.0774 1.46029 9.13403 0.882783C8.19069 0.305276 7.10608 -0.000234842 6.00001 1.35444e-07ZM0.965507 2.735C0.333652 3.70658 -0.00181937 4.84103 7.42118e-06 6C7.42118e-06 9.006 2.21001 11.4955 5.09451 11.932L7.07101 8.5085C6.45787 8.7707 5.76987 8.79897 5.13728 8.58795C4.5047 8.37694 3.97145 7.94129 3.63851 7.3635L0.965507 2.735ZM7.63651 3.818C8.17053 4.21757 8.53934 4.79923 8.67297 5.45266C8.80661 6.1061 8.69578 6.78585 8.36151 7.363L8.36251 7.3635H8.36151L5.68951 11.992C5.79251 11.997 5.89601 12 6.00001 12C9.31351 12 12 9.3135 12 6C12 5.23 11.855 4.4945 11.591 3.818H7.63651ZM6.00001 8.182C5.42131 8.182 4.86631 7.95211 4.4571 7.54291C4.0479 7.1337 3.81801 6.5787 3.81801 6C3.81801 5.4213 4.0479 4.8663 4.4571 4.45709C4.86631 4.04789 5.42131 3.818 6.00001 3.818C6.57871 3.818 7.13371 4.04789 7.54291 4.45709C7.95212 4.8663 8.18201 5.4213 8.18201 6C8.18201 6.5787 7.95212 7.1337 7.54291 7.54291C7.13371 7.95211 6.57871 8.182 6.00001 8.182Z"
                  fill="black"
                  fillOpacity="0.8"
                />
              </g>
              <defs>
                <clipPath id="clip0_68_180">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Connected to the Extension!
          </h2>
          <p className="text-center py-2 text-lg px-8">
            Your Rombo Workspace is now{" "}
            <span className="bg-gradient-to-r font-semibold from-primary-500/100 to-primary-500/50 rounded-sm px-[2px]">
              connected
            </span>{" "}
            to the Chrome Extension, any changes saved in the extension will be{" "}
            <span className="motion-opacity-loop-50 motion-duration-2000 bg-gradient-to-r font-semibold from-black/20 to-black/5 rounded-sm px-[2px]">
              synced
            </span>{" "}
            to your account.
          </p>
          <GetStartedStep hideTitle={true} />

          <div className="flex items-center justify-center w-full mb-4 space-x-4">
            {/* <button
              onClick={() => setExtensionOpen(false)}
              className="text-black/60 flex items-center space-x-2 text-md font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="mr-2"
              >
                <path d="M18 6 6 18" />
                <path d="m6 6 12 12" />
              </svg>
              Dismiss
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtensionModal;

// <div className="flex flex-col text-left items-start bg-yellow-200/80 border border-black/10 outline outline-2 outline-black/5 px-4 py-4 rounded-lg mx-4 my-4">
//   {appData?.getApp?.billing_plan ? (
//     <div className="flex flex-col relative space-y-3 items-start">
//       <h1 className="absolute -top-[28px] bg-black/10 backdrop-blur-md text-black px-3 py-0.5 rounded-md text-md font-bold">
//         You're subscribed to Rombo Pro
//       </h1>
//       <p className="text-sm text-left">
//         Take advantage of Pro features in the extension.
//       </p>
//       <Features />
//       <div className="flex items-center opacity-50 ">
//         <p className="text-xs text-left mr-2">
//           Struggling to access the features?
//         </p>
//         <button
//           onClick={() => window.location.reload()}
//           className="border border-black/10 px-3 py-0.5 rounded-md text-xs"
//         >
//           Refresh page
//         </button>
//       </div>
//     </div>
//   ) : (
//     <div className="flex flex-col relative space-y-3 items-start">
//       <h1 className="absolute -top-[28px] bg-black/10 backdrop-blur-md text-black px-3 py-0.5 rounded-md text-sm font-bold">
//         Subscribe to Rombo Pro
//       </h1>
//       <p className="text-sm text-left">
//         Want to get more out of Rombo? With the Pro plan, you can!
//         <br />
//         You'll also be supporting us continue to develop Rombo's toolkit.
//       </p>
//       {/* <Features /> */}
//       <div className="flex items-center w-full justify-between">
//         <div className="flex items-center opacity-50">
//           <p className="text-xs text-left mr-2">Facing any issues?</p>
//           <button
//             onClick={() => window.location.reload()}
//             className="border border-black/10 px-3 py-0.5 rounded-md text-xs"
//           >
//             Contact us
//           </button>
//         </div>
//         <button
//           className="bg-black my-2 text-white font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
//           onClick={() => {
//             triggerStripeCheckout(appId, appData?.getApp?.billing_email);
//           }}
//         >
//           Upgarde to Pro now
//         </button>
//       </div>
//     </div>
//   )}
// </div>;
