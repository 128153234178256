import { loadStripe } from "@stripe/stripe-js";

async function triggerStripeCheckout(app_id, email) {
  // Fetch the session ID from your server
  const sessionResponse = await fetch(
    "https://be51yaahwe.execute-api.eu-west-1.amazonaws.com/dev/stripeSession",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: { app_id, customer_email: email, plan_type: "unlimited" },
      }),
    }
  );

  const session = await sessionResponse.json();

  // Now we have the session ID
  const sessionId = session.id;

  const stripe = await loadStripe(
    "pk_live_51P3OKmEvUkXVb6ZyjnaFAupiTGtYvUjJU31PkRzqypldtDRBqGEjVLblAuCNY2QbCacOuk2oLN3J7wc1tTDGyWe800wZMaCL2Z"
  );

  const { error } = await stripe.redirectToCheckout({
    sessionId,
  });

  if (error) {
    console.log(error);
    // Add error handling here as needed
  }
}

export default triggerStripeCheckout;
