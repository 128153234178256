import React from "react";
import { RomboLogo, CloseIcon, CreateAccountIcon } from "./icons";

const WelcomeStep = ({ setExtensionOpen, onNext }) => {
  return (
    <div className="text-center items-center flex flex-col items-start">
      <h2 className="text-2xl flex items-center font-bold px-8 pt-4">
        <RomboLogo className="mr-2" />
        Welcome to the Rombo Chrome Extension
      </h2>
      <p className="text-center py-2 w-2/3 text-md px-8 opacity-60">
        The extension allows you to directly animate any element in your
        browser, and export the code to your favourite language!
      </p>
      <p className="text-center py-2 w-2/3 mb-4 text-md px-8 text-sm opacity-80">
        The get the most of the extension — and Rombo's toolkit — we recommend
        creating an account.{" "}
      </p>
      <div className="flex items-center justify-center w-full mb-4 space-x-4">
        <button
          onClick={() => setExtensionOpen(false)}
          className="bg-black/60 text-white flex items-center space-x-2 text-md font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
        >
          <CreateAccountIcon className="mr-2" />
          Create account
        </button>
        <button
          onClick={onNext}
          className="text-black/60 flex items-center space-x-2 text-md font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
        >
          <CloseIcon className="mr-2" />
          Skip for now
        </button>
      </div>
    </div>
  );
};

export default WelcomeStep;
