import { gql } from "@apollo/client";

const RESET_ANIMATIONS = gql`
  mutation resetAnimations($app_id: ID!) {
    resetAnimations(app_id: $app_id) {
      app_id
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $user_id: String!
    $full_name: String!
    $app_id: String!
    $figma_opt: Boolean
    $tailwind_opt: Boolean
  ) {
    updateUser(
      user_id: $user_id
      full_name: $full_name
      app_id: $app_id
      figma_opt: $figma_opt
      tailwind_opt: $tailwind_opt
    ) {
      user_id
      full_name
      app_id
      figma_opt
      tailwind_opt
    }
  }
`;
const ADD_ANIMATION = gql`
  mutation addAnimation(
    $app_id: ID!
    $animation: String!
    $element: String!
    $trigger: String
    $text: String
    $css: String
    $splitter: String
    $animation_amount: Int
    $navigator_page: String
    $animation_speed: Float
    $direction: String
    $clip: Boolean
    $animation_delay: Int
    $billing_plan: String
  ) {
    addAnimation(
      app_id: $app_id
      animation: $animation
      element: $element
      trigger: $trigger
      splitter: $splitter
      animation_amount: $animation_amount
      navigator_page: $navigator_page
      text: $text
      css: $css
      animation_speed: $animation_speed
      animation_delay: $animation_delay
      billing_plan: $billing_plan
      direction: $direction
      clip: $clip
    ) {
      id
      animation
      element_path
      splitter
      element_text
      navigator_page
      animation_speed
      animation_delay
      animation_amount
    }
  }
`;

const REMOVE_ANIMATION = gql`
  mutation removeAnimation($id: ID, $app_id: ID!, $element: String!) {
    removeAnimation(id: $id, app_id: $app_id, element: $element) {
      id
      element_path
      element_text
    }
  }
`;

const TOGGLE_ANIMATION = gql`
  mutation toggleAnimation(
    $id: ID!
    $app_id: ID!
    $animation: String!
    $element: String!
    $text: String
    $trigger: String
    $css: String
  ) {
    toggleAnimation(
      id: $id
      app_id: $app_id
      animation: $animation
      element: $element
      text: $text
      trigger: $trigger
      css: $css
    ) {
      id
      animation
      element_path
      element_text
      is_live
    }
  }
`;

const UPDATE_APP = gql`
  mutation updateApp(
    $app_id: ID!
    $app_name: String
    $app_url: String
    $admin_name: String
    $teammates: [String]
    $billing_email: String
    $billing_notifications: Boolean
  ) {
    updateApp(
      app_id: $app_id
      app_name: $app_name
      app_url: $app_url
      admin_name: $admin_name
      teammates: $teammates
      billing_email: $billing_email
      billing_notifications: $billing_notifications
    ) {
      app_id
      app_name
      app_url
      admin_name
      billing_email
      billing_notifications
    }
  }
`;
const CREATE_APP = gql`
  mutation createApp(
    $app_name: String
    $app_url: String
    $admin_name: String
    $billing_email: String
  ) {
    createApp(
      app_name: $app_name
      app_url: $app_url
      admin_name: $admin_name
      billing_email: $billing_email
    ) {
      app_id
      app_name
      app_url
      admin_name
      billing_email
    }
  }
`;

const DOWNLOAD_SITE = gql`
  mutation downloadSite($app_id: ID!, $app_url: String!) {
    downloadSite(app_id: $app_id, app_url: $app_url) {
      app_id
      cloned_url
    }
  }
`;

const DELETE_APP = gql`
  mutation deleteApp($app_id: ID!, $user_id: ID!) {
    deleteApp(app_id: $app_id, user_id: $user_id) {
      app_id
    }
  }
`;

const CREATE_FIGMA_CODE = gql`
  mutation createFigmaCode($app_id: ID!) {
    createFigmaCode(app_id: $app_id) {
      app_id
    }
  }
`;

export {
  CREATE_APP,
  UPDATE_APP,
  DELETE_APP,
  DOWNLOAD_SITE,
  ADD_ANIMATION,
  REMOVE_ANIMATION,
  RESET_ANIMATIONS,
  TOGGLE_ANIMATION,
  UPDATE_USER,
  CREATE_FIGMA_CODE,
};
