import React, { useState } from "react";

const GetStartedStep = ({ hideTitle = false }) => {
  const [url, setUrl] = useState("");
  const [showError, setShowError] = useState(false);

  const isValidUrl = (urlString) => {
    const localhostRegex = /^localhost(:\d+)?$/;
    const urlRegex = /^(https?:\/\/)?([\w.-]+\.[a-z]{2,})(:\d+)?(\/\S*)?$/i;
    return localhostRegex.test(urlString) || urlRegex.test(urlString);
  };

  const handleGo = () => {
    if (!url.trim() || !isValidUrl(url.trim())) {
      setShowError(true);
      return;
    }
    window.open(
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`,
      "_blank"
    );
  };

  return (
    <div className="px-8">
      <div className="text-center items-center flex flex-col justify-center">
        {!hideTitle && (
          <h2 className="text-2xl flex items-center font-bold px-8 pt-4">
            Let's get started!
          </h2>
        )}
      </div>
      <p className="text-center block m-auto py-2 w-2/3 text-md px-8 opacity-80 mb-2">
        To start using the extension, go to the Rombo Playground or any{" "}
        <span className="font-mono font-[11px] text-white px-0.5 rounded-sm bg-black mx-0.5">
          localhost
        </span>{" "}
        domain.
      </p>{" "}
      <img
        src="https://assets.rombo.co/SelectAnElement.gif"
        alt="Select an element"
        className="block m-auto mb-4 w-2/3 rounded-md outline outline-2 outline-offset-1 outline-black/10"
      />
      <div className="flex flex-col items-center justify-center mb-4 space-y-2">
        <button
          onClick={() => window.open("https://playground.rombo.co", "_blank")}
          className="bg-black/90 text-white flex items-center text-md font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
        >
          Open Playground
        </button>
        OR
        <div className="flex items-center justify-center space-x-2">
          <input
            type="text"
            value={url}
            onChange={(e) => {
              setUrl(e.target.value);
              setShowError(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleGo();
              }
            }}
            placeholder="localhost:3000"
            className={`border border-black/10 outline outline-2 outline-offset-1 ${
              showError ? "outline-red-500" : "outline-black/10"
            } px-3 py-0.5 rounded-md text-md`}
          />
          <button
            onClick={handleGo}
            disabled={!url.trim()}
            className="bg-black/90 text-white flex items-center text-md font-semibold border border-black/10 outline outline-2 outline-offset-1 outline-black/10 px-3 py-0.5 rounded-md text-md"
          >
            Go
          </button>
        </div>
      </div>
      {showError && (
        <p className="text-red-500 text-sm mt-1">Please enter a valid URL</p>
      )}
    </div>
  );
};

export default GetStartedStep;
