import React, { useState } from "react";
import WelcomeStep from "./ExtensionIntro/WelcomeStep";
import GetStartedStep from "./ExtensionIntro/GetStartedStep";

const ExtensionIntro = ({ setExtensionOpen }) => {
  const [step, setStep] = useState(0);

  return (
    <div className="w-full fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute top-0 left-0 inset-0 bg-black opacity-80"></div>
      <div className="relative bg-white w-2/3 rounded-md border border-black/10">
        {!step ? (
          <WelcomeStep
            setExtensionOpen={setExtensionOpen}
            onNext={() => setStep(1)}
          />
        ) : (
          <GetStartedStep />
        )}
      </div>
    </div>
  );
};

export default ExtensionIntro;
