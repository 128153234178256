import React from "react";
import triggerStripeCheckout from "../Helpers/stripeTrigger";

function PricingModal({ setPricingModal, appId, authEmail }) {
  return (
    <div
      onClick={() => setPricingModal(false)}
      className="w-full align-center justify-center bg-white bg-opacity-70 items-center flex fixed backdrop-blur-sm h-full left-0 z-50"
    >
      <div className="w-1/2 h-2/3" onClick={(e) => e.stopPropagation()}>
        <div
          onClick={() => setPricingModal(false)}
          className="opacity-50 hover:opacity-100 cursor-pointer text-md p-2 rounded-md"
        >
          Close x
        </div>
        <div className="flex">
          {/* Basic Plan */}
          <div className="w-1/2 m-1 flex flex-col justify-between px-4 py-2 text-gray-600 bg-white rounded-sm outline outline-1 outline-gray-200">
            {/* ... Basic plan content ... */}
            <div>
              <div className="flex">
                <div className="font-semibold">Basic</div>
                <div className="font-semibold pl-3">
                  <span className="text-xs">$</span>0
                </div>
              </div>
              <div>» Up to 3 Animations </div>
              <div>» Up to 1,000 page views /month</div>
            </div>
            <a
              onClick={() => setPricingModal(false)}
              className="opacity-50 font-semibold"
            >
              Continue with basic »
            </a>
          </div>

          {/* Plus Plan */}
          <div className="w-1/2 m-1 px-4 py-2 bg-primary-300 border border-primary-500 outline outline-1 outline-primary-100 rounded-sm">
            <div>
              <div className="flex">
                <div className="font-semibold">Plus</div>
                <div className="font-semibold pl-3">
                  <span className="text-xs">$</span>9 / month
                  <span className="text-xs ml-3 bg-primary-600 rounded-sm p-0.5">
                    PH50 / -50% 6 MONTHS
                  </span>
                </div>
              </div>
            </div>
            <div>» ∞ Animations</div>
            <div>» 1M page views /monthn</div>
            <div>» Early access to new animations</div>
            <div>» Early access to Figma Plugin</div>
            <button
              onClick={async (e) => {
                document.body.style = "cursor: wait !important;";
                e.target.style = "cursor: wait !important;";
                await triggerStripeCheckout(appId, authEmail);
              }}
              className="mt-2 py-1 px-3 hover:opacity-50 flex shadow-sm border border-dark-100 shadow-gray-200 bg-primary-900"
            >
              <svg
                className="w-4 mr-1 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M11 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" />
                <path
                  fillRule="evenodd"
                  d="M9.896 3.051a2.681 2.681 0 0 1 4.208 0c.147.186.38.282.615.255a2.681 2.681 0 0 1 2.976 2.975.681.681 0 0 0 .254.615 2.681 2.681 0 0 1 0 4.208.682.682 0 0 0-.254.615 2.681 2.681 0 0 1-2.976 2.976.681.681 0 0 0-.615.254 2.682 2.682 0 0 1-4.208 0 .681.681 0 0 0-.614-.255 2.681 2.681 0 0 1-2.976-2.975.681.681 0 0 0-.255-.615 2.681 2.681 0 0 1 0-4.208.681.681 0 0 0 .255-.615 2.681 2.681 0 0 1 2.976-2.975.681.681 0 0 0 .614-.255ZM12 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
                  clipRule="evenodd"
                />
                <path d="M5.395 15.055 4.07 19a1 1 0 0 0 1.264 1.267l1.95-.65 1.144 1.707A1 1 0 0 0 10.2 21.1l1.12-3.18a4.641 4.641 0 0 1-2.515-1.208 4.667 4.667 0 0 1-3.411-1.656Zm7.269 2.867 1.12 3.177a1 1 0 0 0 1.773.224l1.144-1.707 1.95.65A1 1 0 0 0 19.915 19l-1.32-3.93a4.667 4.667 0 0 1-3.4 1.642 4.643 4.643 0 0 1-2.53 1.21Z" />
              </svg>
              Upgrade to Plus
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingModal;
