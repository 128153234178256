import React from "react";
import { useLocation } from "react-router-dom";
import { signUpPasswordless, signInPasswordless } from "./SignUp";

async function getAuthorizedWebflowUser() {
  try {
    const webflow_id = localStorage.getItem("webflow_id");
    const response = await fetch(
      `https://jlm4y0ao0f.execute-api.eu-west-1.amazonaws.com/authorizedUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ auth_token: webflow_id }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching authorized Webflow user:", error);
    throw error;
  }
}
export function WebflowSetup() {
  const { search } = useLocation();
  const [webflowToken, setWebflowToken] = React.useState(null);
  const [webflowUser, setWebflowUser] = React.useState(null);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const webflow_token = urlParams.get("t");

    // Store as webflow_id in local storage
    if (webflow_token) {
      localStorage.setItem("webflow_id", webflow_token);
      return (window.location.href = "/webflow-setup");
    }
    console.log("Auth token should be in storage");
    const webflow_id = localStorage.getItem("webflow_id");
    if (webflow_id) {
      console.log("Webflow ID is in storage", webflow_id);
      setWebflowToken(webflow_id);
    }
    // getAccessToken(code).then(setAccessToken);
  }, []);

  React.useEffect(() => {
    if (webflowToken) {
      getAuthorizedWebflowUser().then(({ data }) => setWebflowUser(data));
      // console.log("Webflow token is in storage", webflowToken);
      // console.log("Webflow user is", webflowUser);
      // setWebflowUser(webflowUser);
    }
  }, [webflowToken]);

  React.useEffect(() => {
    console.log("Webflow user is", webflowUser);
    try {
      if (webflowUser) {
        (async () => {
          await signUpPasswordless(
            webflowUser.email,
            `${webflowUser.name}`,
            "web"
          );
          await signInPasswordless(webflowUser.email);
        })();
      }
    } catch (e) {
      console.log(e);
    }
  }, [webflowUser]);

  return <div>webflow</div>;
}
