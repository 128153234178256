import React from "react";

function ExtensionPromo() {
  return (
    <div className="rounded-md h-[350px] overflow-hidden bg-primary-600 w-full border border-dark-100 outline outline-4 outline-dark-100/30">
      <div className="flex flex-wrap flex-1 tracking-[-0.05px] p-4">
        <div className="items-center w-full">
          <span>Use Rombo's</span>
          <svg
            width="12"
            className="mx-0.5 inline-block"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_68_180)">
              <path
                d="M6.00001 1.35444e-07C4.10501 1.35444e-07 2.41551 0.8785 1.31601 2.2505L3.29251 5.6745C3.37199 5.0123 3.6914 4.40219 4.19032 3.95957C4.68923 3.51695 5.33305 3.27252 6.00001 3.2725H11.3455C10.8427 2.28732 10.0774 1.46029 9.13403 0.882783C8.19069 0.305276 7.10608 -0.000234842 6.00001 1.35444e-07ZM0.965507 2.735C0.333652 3.70658 -0.00181937 4.84103 7.42118e-06 6C7.42118e-06 9.006 2.21001 11.4955 5.09451 11.932L7.07101 8.5085C6.45787 8.7707 5.76987 8.79897 5.13728 8.58795C4.5047 8.37694 3.97145 7.94129 3.63851 7.3635L0.965507 2.735ZM7.63651 3.818C8.17053 4.21757 8.53934 4.79923 8.67297 5.45266C8.80661 6.1061 8.69578 6.78585 8.36151 7.363L8.36251 7.3635H8.36151L5.68951 11.992C5.79251 11.997 5.89601 12 6.00001 12C9.31351 12 12 9.3135 12 6C12 5.23 11.855 4.4945 11.591 3.818H7.63651ZM6.00001 8.182C5.42131 8.182 4.86631 7.95211 4.4571 7.54291C4.0479 7.1337 3.81801 6.5787 3.81801 6C3.81801 5.4213 4.0479 4.8663 4.4571 4.45709C4.86631 4.04789 5.42131 3.818 6.00001 3.818C6.57871 3.818 7.13371 4.04789 7.54291 4.45709C7.95212 4.8663 8.18201 5.4213 8.18201 6C8.18201 6.5787 7.95212 7.1337 7.54291 7.54291C7.13371 7.95211 6.57871 8.182 6.00001 8.182Z"
                fill="#212023"
              />
            </g>
            <defs>
              <clipPath id="clip0_68_180">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>
            {" "}
            Extension to build with the visual animator on any site or
          </span>
          <span className="font-mono bg-dark-900 px-0.5 mx-0.5 text-white mt-1 w-full">
            localhost
          </span>
        </div>
        <div className="my-6 flex flex-col">
          <button
            className="font-semibold items-center flex bg-dark-900 text-white px-3 py-1.5 outline outline-2 outline-black/30 rounded-md"
            data-signup
          >
            <svg
              width="14"
              className="mr-2 inline-block"
              height="14"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_68_180)">
                <path
                  d="M6.00001 1.35444e-07C4.10501 1.35444e-07 2.41551 0.8785 1.31601 2.2505L3.29251 5.6745C3.37199 5.0123 3.6914 4.40219 4.19032 3.95957C4.68923 3.51695 5.33305 3.27252 6.00001 3.2725H11.3455C10.8427 2.28732 10.0774 1.46029 9.13403 0.882783C8.19069 0.305276 7.10608 -0.000234842 6.00001 1.35444e-07ZM0.965507 2.735C0.333652 3.70658 -0.00181937 4.84103 7.42118e-06 6C7.42118e-06 9.006 2.21001 11.4955 5.09451 11.932L7.07101 8.5085C6.45787 8.7707 5.76987 8.79897 5.13728 8.58795C4.5047 8.37694 3.97145 7.94129 3.63851 7.3635L0.965507 2.735ZM7.63651 3.818C8.17053 4.21757 8.53934 4.79923 8.67297 5.45266C8.80661 6.1061 8.69578 6.78585 8.36151 7.363L8.36251 7.3635H8.36151L5.68951 11.992C5.79251 11.997 5.89601 12 6.00001 12C9.31351 12 12 9.3135 12 6C12 5.23 11.855 4.4945 11.591 3.818H7.63651ZM6.00001 8.182C5.42131 8.182 4.86631 7.95211 4.4571 7.54291C4.0479 7.1337 3.81801 6.5787 3.81801 6C3.81801 5.4213 4.0479 4.8663 4.4571 4.45709C4.86631 4.04789 5.42131 3.818 6.00001 3.818C6.57871 3.818 7.13371 4.04789 7.54291 4.45709C7.95212 4.8663 8.18201 5.4213 8.18201 6C8.18201 6.5787 7.95212 7.1337 7.54291 7.54291C7.13371 7.95211 6.57871 8.182 6.00001 8.182Z"
                  fill="white"
                  fillOpacity="0.8"
                />
              </g>
              <defs>
                <clipPath id="clip0_68_180">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Install on Chrome
          </button>
        </div>
      </div>
      <div className="flex items-center flex-1 translate-x-[25%] mt-4 overflow-hidden">
        <img
          className="rounded-[3px]"
          src="https://s3.eu-west-2.amazonaws.com/assets.rombo.co/rombo-visual-animator-preview.gif"
        />
      </div>
    </div>
  );
}

export default ExtensionPromo;
